import {createBrowserRouter, Navigate, useParams} from "react-router-dom";
import {ErrorPage} from "../page";
import React from "react";
import {OrganizationApp} from "./OrganizationApp";
import {
    organizationListStore,
    organizationStore,
    organizationUserGroupStore,
    pageLoadingStore,
    registrationOverviewFilterStore,
    registrationOverviewStore,
    registrationRequestHistoryStore,
    registrationStore,
    registrationValidationStore,
    routeStore,
    tenantConfigStore,
    userListStore,
    userOrganizationRoleStore,
    userStore
} from "../../store";
import {UserOverviewApp} from "./UserOverviewApp";
import {UserDetailApp} from "./UserDetailApp";
import {RegistrationApp} from "./RegistrationApp";
import {TenantConfigurationApp} from "./TenantConfigurationApp";

function LoginRedirect() {
    return <Navigate to={`/registration`} replace={true} />
}

function RegistrationIdRedirect() {
    const params = useParams()
    const id = params.id
    return <Navigate to={`/registration/${id}`} replace={true} />
}


class RProvider {
    private _router: any = null
    get router() {
        if (this._router === null) {
            this._router = createBrowserRouter([
                {
                    path: "/",
                    element: <Navigate to={"/registration"} replace={true} />,
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/vehicleregistration/:id",
                    element: <RegistrationIdRedirect />,
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/vehicleregistration",
                    element: <Navigate to={"/registration"} replace={true} />,
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/login_redirect/*",
                    element: <LoginRedirect />,
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/organization/settings",
                    element: <TenantConfigurationApp />,
                    loader: async () => {
                        pageLoadingStore.loading = true
                        routeStore.active = '/organization/settings'
                        await tenantConfigStore.load()
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/organization/branch",
                    element: <OrganizationApp />,
                    loader: async () => {
                        pageLoadingStore.loading = true
                        routeStore.active = '/organization/branch'
                        organizationStore.organizationId = undefined
                        await organizationListStore.load()
                        await organizationStore.load()
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/organization/branch/:id",
                    element: <OrganizationApp />,
                    loader: async ({params}) => {
                        pageLoadingStore.loading = true
                        routeStore.active = `/organization/branch/${params.id}`
                        organizationStore.organizationId = params.id
                        await organizationStore.load()
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/organization/branch/:id/:tab",
                    element: <OrganizationApp />,
                    loader: async ({params}) => {
                        pageLoadingStore.loading = true
                        routeStore.active = `/organization/branch/${params.id}/${params.tab}`
                        organizationStore.organizationId = params.id
                        await organizationStore.load()
                        organizationUserGroupStore.organizationId = params.id
                        await organizationUserGroupStore.load()
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/organization/user",
                    element: <UserOverviewApp />,
                    loader: async ({request}) => {
                        pageLoadingStore.loading = true
                        routeStore.active = `/organization/user`
                        const searchParams = new URL(request.url).searchParams
                        userListStore.search = searchParams.get("search") || ""
                        userListStore.page = parseInt(searchParams.get("page") || "") || 1
                        userListStore.size = parseInt(searchParams.get("size") || "") || 25
                        await userListStore.load()
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/organization/user/:userId/:tab",
                    element: <UserDetailApp />,
                    loader: async ({params}) => {
                        pageLoadingStore.loading = true
                        routeStore.active = `/organization/user/${params.user}/${params.tab}`
                        await userStore.setUser(params.userId)
                        await userOrganizationRoleStore.setUser(params.userId)
                        if (params.tab === 'organization') {
                            await organizationListStore.load()
                        }
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/organization/user/:userId",
                    element: <UserDetailApp />,
                    loader: async ({params}) => {
                        pageLoadingStore.loading = true
                        routeStore.active = `/organization/user/${params.userId}`
                        await userStore.setUser(params.userId)
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/organization",
                    element: <Navigate to={"/organization/branch"} replace={true} />,
                    loader: async () => {
                        routeStore.active = `/organization/branch`
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/registration/:id/:tab",
                    element: <RegistrationApp />,
                    loader: async ({params}) => {
                        pageLoadingStore.loading = true
                        routeStore.active = `/registration/${params.id}/${params.tab}`
                        registrationValidationStore.activated = false
                        await registrationStore.load(params.id)
                        if (params.tab === 'requestlog') {
                            await registrationRequestHistoryStore.load(params.id!!)
                        }
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
                {
                    path: "/registration/:id",
                    element: <RegistrationApp />,
                    loader: async ({params}) => {
                        pageLoadingStore.loading = true
                        routeStore.active = `/registration/${params.id}`
                        await organizationListStore.load()
                        await registrationStore.load(params.id)
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                }, {
                    path: "/registration",
                    element: <RegistrationApp />,
                    loader: async ({request}) => {
                        pageLoadingStore.loading = true
                        routeStore.active = `/registration`
                        const searchParams = new URL(request.url).searchParams
                        registrationOverviewStore.init(searchParams)
                        await registrationOverviewStore.load()

                        registrationOverviewFilterStore.init(searchParams)
                        await registrationOverviewFilterStore.load()
                        pageLoadingStore.loading = false
                        return null
                    },
                    errorElement: <ErrorPage/>
                },
            ])
        }
        return this._router
    }
}

const provider = new RProvider()

export {
    provider
}
