import {
    AddDocumentParamsEnum,
    AddDocumentParamsRequiredDocumentTypeEnum,
    DeliveryAddressDtoGenderEnum,
    DeliveryInformationDtoDeliveryServiceTypeEnum,
    GenerateDocumentParamsEnum,
    GenerateDocumentParamsRequiredDocumentTypeEnum,
    GetDocumentParamsEnum,
    GetDocumentParamsRequiredDocumentTypeEnum,
    HolderDtoEconomicSectorEnum,
    HolderDtoGenderEnum,
    ImportHalterDtoBerufsgruppeEnum,
    ListAvailableDocumentsEnum,
    RegistrationDtoServiceTypeEnum,
    RegistrationDtoVehicleTypeEnum,
    RegistrationListItemDtoOrderStatusEnum,
    RegistrationListItemDtoOrderTypeEnum,
    RegistrationOfficePortalAvailabilityDtoStatusEnum,
    RegistrationStatusDtoStatusEnum,
    RemoveDocumentParamsEnum,
    RemoveDocumentParamsRequiredDocumentTypeEnum,
    SendDocumentToHolderParamsEnum,
    SendDocumentToHolderParamsRequiredDocumentTypeEnum
} from "./Api";

const deliveryInformationDeliveryServiceTypes = new Map<DeliveryInformationDtoDeliveryServiceTypeEnum, string>()

deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.VEHICLE_OWNER, 'Halter des Fahrzeugs')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.AUTHORIZED_REPRESENTATIVE, 'Bevollmächtigter')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.KFZ_REGISTRATION_SERVICE, 'ZLD')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP, 'Abholung')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_VEHICLE_OWNER, 'Abholung durch Halter des Fahrzeugs')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_KFZ_REGISTRATION_SERVICE, 'Abholung durch Filiale/ZLD')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_AUTHORIZED_REPRESENTATIVE, 'Abholung durch Bevollmächtigten')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_THIRD, 'Abholung durch Dritten')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING, 'Zusendung')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER, 'Zusendung an Halter des Fahrzeugs')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_REGISTRATION_SERVICE, 'Zusendung an ZLD')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_AUTHORIZED_REPRESENTATIVE, 'Zustellung an Bevollmächtigten')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD, 'Zusendung an einen Dritten')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_USER_ACCOUNT, 'SENDING_TO_USER_ACCOUNT')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_SABRINA, 'SENDING_TO_SABRINA')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.DHL, 'DHL')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.DPD, 'DPD')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.GLS, 'GLS')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.FEDEX, 'FEDEX')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.GO, 'GO')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.HERMES, 'HERMES')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.TNT, 'TNT')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.TRANS_O_FLEX, 'TRANS O FLEX')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.UPS, 'UPS')
deliveryInformationDeliveryServiceTypes.set(DeliveryInformationDtoDeliveryServiceTypeEnum.OTHER, 'OTHER')

function deliveryInformationDeliveryServiceType(val: DeliveryInformationDtoDeliveryServiceTypeEnum) {
    return deliveryInformationDeliveryServiceTypes.get(val) || val
}


const deliveryAddressGenders = new Map<DeliveryAddressDtoGenderEnum, string>()
deliveryAddressGenders.set(DeliveryAddressDtoGenderEnum.MALE, 'Herr')
deliveryAddressGenders.set(DeliveryAddressDtoGenderEnum.FEMALE, 'Frau')
deliveryAddressGenders.set(DeliveryAddressDtoGenderEnum.DIVERSE, 'Divers')
deliveryAddressGenders.set(DeliveryAddressDtoGenderEnum.NOT_PROVIDED, 'Nicht angegeben')

function deliveryAddressGender(val: DeliveryAddressDtoGenderEnum | HolderDtoGenderEnum) {
    return deliveryAddressGenders.get(val as any as DeliveryAddressDtoGenderEnum) || val
}

function orderType(orderType: RegistrationListItemDtoOrderTypeEnum | RegistrationDtoServiceTypeEnum): string {
    switch (orderType) {
        case RegistrationListItemDtoOrderTypeEnum.AB:
            return "Außerbetriebsetzung"
        case RegistrationListItemDtoOrderTypeEnum.NZ:
            return "Neuzulassung"
        case RegistrationListItemDtoOrderTypeEnum.TZ:
            return "Tageszulassung"
        case RegistrationListItemDtoOrderTypeEnum.UI:
            return "Umschreibung Innerhalb"
        case RegistrationListItemDtoOrderTypeEnum.UM:
            return "Umschreibung von Außerhalb mit Halterwechsel"
        case RegistrationListItemDtoOrderTypeEnum.UO:
            return "Umschreibung von Außerhalb ohne Halterwechsel"
        case RegistrationListItemDtoOrderTypeEnum.WZ:
            return "Wiederzulassung"
        case RegistrationListItemDtoOrderTypeEnum.HA:
            return "Halteränderung"
        default:
            return orderType
    }
}

function documentType(
    type: AddDocumentParamsEnum
        | GenerateDocumentParamsRequiredDocumentTypeEnum
        | GenerateDocumentParamsEnum
        | SendDocumentToHolderParamsRequiredDocumentTypeEnum
        | SendDocumentToHolderParamsEnum
        | RemoveDocumentParamsRequiredDocumentTypeEnum
        | RemoveDocumentParamsEnum
        | GetDocumentParamsRequiredDocumentTypeEnum
        | GetDocumentParamsEnum
        | AddDocumentParamsRequiredDocumentTypeEnum
        | ListAvailableDocumentsEnum
): string {
    switch (type) {
        case AddDocumentParamsEnum.FEE_RECEIPT:
            return 'Gebührenbescheid';
        case AddDocumentParamsEnum.REGISTRATION_RECEIPT:
            return 'Zulassungsbescheid';
        case AddDocumentParamsEnum.PRELIMINARY_REGISTRATION_PROOF:
            return 'Vorläufiger Zulassungsnachweis';
        case AddDocumentParamsEnum.DEREGISTRATION_RECEIPT:
            return 'Abmeldebescheinigung';
        case AddDocumentParamsEnum.SEPA:
            return 'SEPA Mandat';
        case AddDocumentParamsEnum.POA:
            return 'Vollmacht';
        case AddDocumentParamsEnum.DATA_CONSENT:
            return 'Einwilligung zur Datenverarbeitung';
        case AddDocumentParamsEnum.REJECTION_RECEIPT:
            return 'Ablehnungsbescheid'
        default:
            return '';
    }
}

function documentFileName(
    type: AddDocumentParamsEnum
        | GenerateDocumentParamsRequiredDocumentTypeEnum
        | GenerateDocumentParamsEnum
        | SendDocumentToHolderParamsRequiredDocumentTypeEnum
        | SendDocumentToHolderParamsEnum
        | RemoveDocumentParamsRequiredDocumentTypeEnum
        | RemoveDocumentParamsEnum
        | GetDocumentParamsRequiredDocumentTypeEnum
        | GetDocumentParamsEnum
        | AddDocumentParamsRequiredDocumentTypeEnum
        | ListAvailableDocumentsEnum
): string {
    switch (type) {
        case AddDocumentParamsEnum.FEE_RECEIPT:
            return 'Gebührenbescheid.pdf';
        case AddDocumentParamsEnum.REGISTRATION_RECEIPT:
            return 'Zulassungsbescheid.pdf';
        case AddDocumentParamsEnum.PRELIMINARY_REGISTRATION_PROOF:
            return 'VorlaeufigerZulassungsnachweis.pdf';
        case AddDocumentParamsEnum.DEREGISTRATION_RECEIPT:
            return 'Abmeldebescheinigung.pdf';
        case AddDocumentParamsEnum.SEPA:
            return 'SEPA-Mandat.pdf';
        case AddDocumentParamsEnum.POA:
            return 'Vollmacht.pdf';
        case AddDocumentParamsEnum.DATA_CONSENT:
            return 'Daten_Einwilligungserklärung.pdf';
        case AddDocumentParamsEnum.REJECTION_RECEIPT:
            return 'Ablehnungsbescheid.pdf';
        default:
            return '';
    }
}

function orderStatus(orderStatus: RegistrationListItemDtoOrderStatusEnum | RegistrationStatusDtoStatusEnum): string {
    switch (orderStatus) {
        case RegistrationListItemDtoOrderStatusEnum.CREATED:
            return "Zulassungsauftrag angelegt"
        case RegistrationListItemDtoOrderStatusEnum.FINISHED:
            return "Fertig"
        case RegistrationListItemDtoOrderStatusEnum.FAILED:
            return "Zulassungsauftrag fehlgeschlagen"
        case RegistrationListItemDtoOrderStatusEnum.SUBMITTED:
            return "Zulassung beantragt"
        case RegistrationListItemDtoOrderStatusEnum.SUBMISSION_FAILED:
            return "Zulassung konnte nicht beantragt werden"
        case RegistrationListItemDtoOrderStatusEnum.SUCCESS:
            return "Zugelassen"
        default:
            return orderStatus
    }
}

function economicSectors(economicSector: HolderDtoEconomicSectorEnum | ImportHalterDtoBerufsgruppeEnum) {
    switch (economicSector) {
        case HolderDtoEconomicSectorEnum.A00:
            return 'Landwirtschaft, Jagd, Forstwirtschaft, Fischerei'
        case HolderDtoEconomicSectorEnum.B00:
            return 'Gewinnung von Erdöl und Erdgas, Bergbau (Kohle, Erz, Stein, sonstiges)'
        case HolderDtoEconomicSectorEnum.C01:
            return 'Herstellung/Verarbeitung von Nahrungsmitteln  und Getränken - incl. Obst- und Gemüseverarbeitung'
        case HolderDtoEconomicSectorEnum.C02:
            return 'Kokerei und Mineralölverarbeitung'
        case HolderDtoEconomicSectorEnum.C03:
            return 'Herstellung/Verarbeitung von chemischen u. pharmazeutischen Erzeugnissen'
        case HolderDtoEconomicSectorEnum.C04:
            return 'Herstellung von Kraftwagen und -teilen, Sonstiger Fahrzeugbau f. d. Straßenverkehr'
        case HolderDtoEconomicSectorEnum.C05:
            return 'Maschinenbau, Metallerzeugung und -bearbeitung, elektr. Ausrüstungen, Reparatur von Maschinen'
        case HolderDtoEconomicSectorEnum.C99:
            return 'Sonstige Herstellung von Waren: Futtermittel, Tabak, Textilien, Holz, Kork, Gummi-u.Kunststoffwaren'
        case HolderDtoEconomicSectorEnum.D00:
            return 'Energieversorgung'
        case HolderDtoEconomicSectorEnum.E00:
            return 'Wasserversorgung; Abwasser- und Abfallentsorgung und Beseitigung von Umweltverschmutzungen'
        case HolderDtoEconomicSectorEnum.F00:
            return 'Baugewerbe/Bau'
        case HolderDtoEconomicSectorEnum.G01:
            return 'Handel mit Kraftfahrzeugen, Instandhaltung u. Reparatur von Kraftfahrzeugen'
        case HolderDtoEconomicSectorEnum.G02:
            return 'Groß- und Einzelhandel: Nahrungs-u. Genussmittel, Getränke, landwirtschaftliche Grundstoffe, Tiere'
        case HolderDtoEconomicSectorEnum.G03:
            return 'Handel mit Brennstoffen, Metall u. Kunststoffwaren für Bauzwecke, Motorenkraftstoffen'
        case HolderDtoEconomicSectorEnum.G99:
            return 'Sonstiger Groß- und Einzelhandel'
        case HolderDtoEconomicSectorEnum.H01:
            return 'Güterbeförderung im Straßenverkehr, Post-, Kurier- und Expressdienste'
        case HolderDtoEconomicSectorEnum.H02:
            return 'Personenbeförderung im Straßenverkehr u. sonstigem Landverkehr'
        case HolderDtoEconomicSectorEnum.H03:
            return 'Güter- und Personenbeförderung im Eisenbahnverkehr,in der Schiff- und Luftfahrt'
        case HolderDtoEconomicSectorEnum.H04:
            return 'Speditionen und Lagerei; Erbringung von anderenDienstleistungen für den Verkehr'
        case HolderDtoEconomicSectorEnum.H05:
            return 'Arzneimitteltransporte'
        case HolderDtoEconomicSectorEnum.H06:
            return 'Deutsche Bahn AG'
        case HolderDtoEconomicSectorEnum.H07:
            return 'Deutsche Post AG'
        case HolderDtoEconomicSectorEnum.I00:
            return 'Gastgewerbe/Beherbergung und Gastronomie'
        case HolderDtoEconomicSectorEnum.J01:
            return 'Telekommunikation, Verlagswesen, Kinos, Tonstudios, Rundfunkanstalten, Erbringung von DL der IT'
        case HolderDtoEconomicSectorEnum.J02:
            return 'Deutsche Telekom AG'
        case HolderDtoEconomicSectorEnum.K00:
            return 'Erbringung von Finanz- und Versicherungsdienstleistungen'
        case HolderDtoEconomicSectorEnum.L00:
            return 'Grundstücks- und Wohnungswesen'
        case HolderDtoEconomicSectorEnum.M01:
            return 'Rechts- und Steuerberatung, Wirtschaftsprüfung, Unternehmensberatung, Architektur, Forschung,Werbung'
        case HolderDtoEconomicSectorEnum.M02:
            return 'Tierärzte'
        case HolderDtoEconomicSectorEnum.N01:
            return 'Vermietung von Gebrauchsgütern, Vermittlung v. Arbeitskräften, Reisebüros, Gebäudebetreuung, Gartenb'
        case HolderDtoEconomicSectorEnum.N02:
            return 'Vermietung von Kfz ohne Gestellung eines Fahrers'
        case HolderDtoEconomicSectorEnum.O01:
            return 'Sonstige Öffentliche Verwaltung, Verteidigung, auswärtige Angelegenheiten, Sozialversicherung'
        case HolderDtoEconomicSectorEnum.O02:
            return 'Berhörden, Polizei, Zivil- und Katastrophenschutz, Feuerwehren'
        case HolderDtoEconomicSectorEnum.P00:
            return 'Erziehung und Unterricht'
        case HolderDtoEconomicSectorEnum.Q00:
            return 'Gesundheits- und Sozialwesen (Krankenhäuser, Ärzte, Pflegeheime)'
        case HolderDtoEconomicSectorEnum.R00:
            return 'Kunst, Unterhaltung, Erholung, Sport'
        case HolderDtoEconomicSectorEnum.S00:
            return 'Kirchliche/religiöse Vereinigungen, politische Parteien, Reparatur von DV-Geräten'
        case HolderDtoEconomicSectorEnum.U00:
            return 'Exterriotoriale Organisationen und Körperschaftn'
        default: return economicSector
    }
}

function vehicleType(type: RegistrationDtoVehicleTypeEnum): string {
    switch (type) {
        case RegistrationDtoVehicleTypeEnum.CAR: return 'PKW/LKW'
        case RegistrationDtoVehicleTypeEnum.MOTORCYCLE: return 'Motorrad'
        case RegistrationDtoVehicleTypeEnum.TRAILER: return 'Anhänger'
        default: return type
    }
}

function registrationOfficePortalAvailabilityStatus(status: RegistrationOfficePortalAvailabilityDtoStatusEnum | undefined): string {
    if (!status) {
        return ''
    }
    switch (status) {
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FINDER_SYSTEM_NOT_AVAILABLE: return 'Der Zuständigkeitsfinder antwortet derzeit nicht.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.REGISTRATION_OFFICE_FOR_ADDRESS_NOT_AVAILABLE: return 'Zur angegebenen Anschrift konnte keine Zulassungsbehörde ermittelt werden.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FOR_REGISTRATION_OFFICE_NOT_AVAILABLE: return 'Zur angegebenen Zulassungsbehörde konnte kein i-Kfz-Portal ermittelt werden.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FOR_REGISTRATION_OFFICE_AVAILABLE: return 'Das i-Kfz Portal ist verfügbar.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.PORTAL_FOR_REGISTRATION_OFFICE_TEMPORARY_NOT_AVAILABLE: return 'Das i-Kfz Portal steht aktuell nicht zur Verfügung.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.STATUS_FOR_REGISTRATION_OFFICE_PORTAL_NOT_IN_DATABASE: return 'Noch kein Portalstatus für diese PLZ bekannt.'
        case RegistrationOfficePortalAvailabilityDtoStatusEnum.ERROR: return 'Es ist ein Fehler aufgetreten'
    }
}


export {
    deliveryInformationDeliveryServiceType,
    deliveryAddressGender,
    orderType,
    orderStatus,
    documentType,
    documentFileName,
    economicSectors,
    vehicleType,
    registrationOfficePortalAvailabilityStatus,
}
