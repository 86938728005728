import {observer} from "mobx-react";
import {OrganizationForm} from "./OrganizationForm";
import {DialogStore, growlStore, organizationStore, organizationUserGroupStore, userStore} from "../../../store";
import {AppHeader, AppPage} from "../../app";
import {Dialog, Tab} from "../../ui";
import {useParams} from "react-router-dom";
import {OrganizationUserGroupList} from "./OrganizationUserGroupList";
import {useState} from "react";
import {OrganizationUserGroupCreateForm} from "./OrganizationUserGroupCreateForm";

const OrganizationDetailPage = observer(function OrganizationDetailPage() {
    const {id, tab} = useParams();
    const [dialogStore] = useState(new DialogStore())

    function openCreateDialog() {
        organizationUserGroupStore.reset()
        dialogStore.modalStore.open()
    }

    return <AppPage>
        <AppHeader title={"Standorte"}>
            <button className={`button is-primary`}
                    onClick={() => openCreateDialog()}>Benutzer zuordnen
            </button>
        </AppHeader>
        <div className="tabs">
            <ul>
                <Tab title={"Übersicht"} name={"detail"} defaultTab={true}
                     path={`/organization/branch/${id}/detail`}/>
                <Tab title={"Benutzer"} name={"user"} path={`/organization/branch/${id}/user`}/>
            </ul>
        </div>
            {(tab === 'detail' || !tab) && <div style={{padding: "1.5em", paddingTop: "0"}}><OrganizationForm/>
                <div>
                    <button className={`button is-primary ${organizationStore.loading ? 'is-loading' : ''}`}
                            onClick={async () => {
                                await organizationStore.save()
                                growlStore.addMessage({
                                    message: `Standort gespeichert`,
                                    type: 'success',
                                    autoHide: true
                                })
                            }}>Speichern
                    </button>
                </div>
            </div>}
            {(tab === 'user') && <OrganizationUserGroupList/>}

        <Dialog store={dialogStore} title={<>
            <span className="material-symbols-outlined"
                  style={{color: "var(--bulma-primary)", marginRight: "0.25em"}}>group</span> Benutzer zuordnen
        </>} primaryAction={async () => {
            await organizationUserGroupStore.createUserMapping()
            await userStore.load()
            await organizationUserGroupStore.load()
            organizationUserGroupStore.reset()
            dialogStore.modalStore.close()
            growlStore.addMessage({
                message: `Zuweisung erfolgreich`,
                type: 'success',
                autoHide: true
            })
            return null
        }} primaryActionTitle={"Anlegen"}>
            <OrganizationUserGroupCreateForm />
        </Dialog>
    </AppPage>
})

export {
    OrganizationDetailPage
}
